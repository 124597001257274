@keyframes floating {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px); /* Moves up by 10px */
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes pulsate-green {
    0% {
      transform: scale(1);
      opacity: 0.8;
    }
    50% {
      transform: scale(1.2); /* Slightly expand the layer */
      opacity: 0.4;
    }
    100% {
      transform: scale(1);
      opacity: 0.8;
    }
  }
  
  @keyframes pulsate-blue {
    0% {
      transform: scale(1.2); /* Starts slightly bigger */
      opacity: 0.6;
    }
    50% {
      transform: scale(1.4); /* Expands slightly more */
      opacity: 0.3;
    }
    100% {
      transform: scale(1.2);
      opacity: 0.6;
    }
  }
  
  .floating-whatsapp-btn {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1000;
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    animation: floating 7s ease-in-out infinite; /* Floating animation */
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .floating-whatsapp-btn:hover {
    transform: scale(1.1);
  }
  
  /* Green pulsating effect using ::before */
  .floating-whatsapp-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%; /* Matches the circular shape of the button */
    border: 3px solid rgba(37, 211, 102, 0.5); /* Light green border */
    animation: pulsate-green 1.5s infinite ease-in-out; /* Green pulsating animation */
    z-index: -2; /* Place behind the button */
  }
  
  /* Blue pulsating effect using ::after */
  .floating-whatsapp-btn::after {
    content: "";
    position: absolute;
    top: -4px; /* Slightly outside the green border */
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 50%; /* Matches the circular shape of the button */
    border: 3px solid rgba(21, 40, 214, 0.4); /* Light blue border */
    animation: pulsate-blue 2s infinite ease-in-out; /* Blue pulsating animation */
    z-index: -3; /* Place behind the green layer */
  }
  
/* Tooltip text */
.whatsapp-tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 60px; /* Positioning the text above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  transition: opacity 0.3s ease;
}

.floating-whatsapp-btn:hover .whatsapp-tooltip {
  visibility: visible;
  opacity: 1;
}
